<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Busque um cliente 🔍">
        <b-card-text>No campo abaixo busque por: Email, Nome, Nickname</b-card-text>
        <div class="d-flex">
          <b-form-input
            id="live-query"
            v-model="searchQuery"
            icon-no-border
            placeholder="Digite e pressione ENTER"
            class="input-rounded-full col"
            icon="icon-search"
            icon-pack="feather"
            :state="docState"
            size="lg"
            aria-describedby="input-live-help input-live-feedback"
            @keyup.enter.native="preSearch()"
          />
          <b-form-select
            v-model="docKind"
            size="lg"
            class="col"
          >
            <b-form-select-option
              :value="null"
              :disabled="true"
            >
              Selecione um tipo de documento
            </b-form-select-option>
            <b-form-select-option
              v-for="doc in docTypes"
              :key="doc"
              :data="doc"
              :value="doc"
            >
              {{ doc }}
            </b-form-select-option>
          </b-form-select>
          <b-button
            size="sm"
            variant="primary"
            class="btn-tour-finish"
            @click="preSearch()"
          >
            <span class="mr-25 align-middle">Buscar</span>
            <feather-icon
              icon="CheckCircleIcon"
              size="12"
            />
          </b-button>
        </div>

        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>
      </b-card>
      <general-clients-list
        :searched-users="queryResult"
        :context-actions="['details', 'balance-tool']"
      />
      <nav aria-label="...">
        <ul class="pagination">
          <li
            v-if="pageInfo.currentPage - 1 > 0"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(-1)"
            >Anterior</span>
          </li>
          <li class="page-item active">
            <span class="page-link">
              {{ pageInfo.currentPage }}
            </span>
          </li>
          <li
            v-if="pageInfo.currentPage < pageInfo.lastPage"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(+1)"
            >Proxima</span>
          </li>
        </ul>
      </nav>

    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BFormInput, BFormInvalidFeedback, BFormSelect, BFormSelectOption,
} from 'bootstrap-vue'
import GeneralClientsList from '@/components/clients/GeneralClientsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    GeneralClientsList,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation,
    BFormSelect,
    BFormSelectOption,
    BButton,

  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult: null,
      pageToSearch: 1,
      pageInfo: {},
      docKind: null,
      docTypes: ['CPF', 'EMAIL', 'FULLNAME', 'NICKNAME', 'PHONE'],
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    this.searchUser()
  },
  methods: {
    searchUser() {
      if (this.searchQuery == '' || !document.getElementById('live-query').getAttribute('aria-invalid')) {
        this.$apollo.query({
          query: gql`query($value: String, $offset:Int){
            user_documents_by_value(
              first:200,
              value : $value,
              page:$offset
            ){
              data{
                value,
                user{
                  id,
                  username,
                  status,
                  created_at,
                  documents{
                    id,
                    type,
                    value
                  }
                },
              },
              paginatorInfo{
                currentPage,
                lastPage
              }
            }
          }`,
          variables: {
            value: `%${this.searchQuery}%`,
            offset: this.pageToSearch,
          },
          client: 'identityClient',
        }).then(data => {
          // Result
          this.pageInfo = data.data.user_documents_by_value.paginatorInfo
          this.queryResult = data.data.user_documents_by_value.data

          console.log("no encontre ",this.queryResult.length)

          if (this.queryResult.length === 0) {
            this.searchUserInUser()
          } else {
            this.queryResult = _.pluck(this.queryResult, 'user')
          }
        }).catch(error => {
          console.error(error)
        })
      }
    },
    searchUserInUser() {
      if (this.searchQuery == '' || !document.getElementById('live-query').getAttribute('aria-invalid')) {
        this.$apollo.query({
          query: gql`query($value: String){
            users_username_like(
              username: $value,
              first: 50
            ){
              data{
                
                  id,
                  username,
                  status,
                  created_at,
                  documents{
                    id,
                    type,
                    value
                  }
              }
              paginatorInfo{
                currentPage,
                lastPage
              }
            }
          }`,
          variables: {
            value: `%${this.searchQuery}%`,
          },
          client: 'identityClient',
        }).then(data => {
          // Result
          this.pageInfo = data.data.users_username_like.paginatorInfo
          this.queryResult = data.data.users_username_like.data
        }).catch(error => {
          console.error(error)
        })
      }
    },

    searchUserByKind() {
      if (this.searchQuery == '' || !document.getElementById('live-query').getAttribute('aria-invalid')) {
        this.$apollo.query({
          query: gql`query($value: String, $offset:Int,$type:String){
            user_documents_by_value_and_type(
              first:200,
              value : $value,
              type:$type,
              page:$offset
            ){
              data{
                value,
                user{
                  id,
                  username,
                  status,
                  created_at,
                  documents{
                    id,
                    type,
                    value
                  }
                },
              },
              paginatorInfo{
                currentPage,
                lastPage
              }
            }
          }`,
          variables: {
            value: `%${this.searchQuery}%`,
            offset: this.pageToSearch,
            type: this.docKind,
          },
          client: 'identityClient',
        }).then(data => {
          // Result
          this.pageInfo = data.data.user_documents_by_value_and_type.paginatorInfo
          this.queryResult = data.data.user_documents_by_value_and_type.data
          this.queryResult = _.pluck(this.queryResult, 'user')
        }).catch(error => {
          console.error(error)
        })
      }
    },
    preSearch() {
      if (!this.docKind) {
        this.searchUser()
      } else {
        this.searchUserByKind()
      }
    },
    paginate(eV) {
      if (this.pageToSearch == 1) {
        this.pageToSearch = 2
      } else {
        this.pageToSearch += eV
      }
      this.searchUser()
    },
  },
}
</script>

<style>

</style>
